import React from "react";
import "./ProgressBar.css"; // Add styles in a separate CSS file
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const ProgressBar = ({ currentStatus }) => {
  // Define the statuses
  const steps = ["Pending", "Download", "Progress", "Complete"];

  // Get index of current status
  const currentStepIndex = steps.indexOf(currentStatus);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="progress-bar-container">
        {steps.map((step, index) => (
          <div key={index} className="step-container">
            <div
              className={`circle ${index <= currentStepIndex ? "active" : ""}`}
              style={{backgroundColor: index - 1 == currentStepIndex ?"white":null}}
            >
              {index <= currentStepIndex ? (
                <CheckCircleIcon
                  className={`renew-icon`}
                  style={{ color: "white", fontSize: 16 }}
                />
              ) : index - 1 == currentStepIndex ? (
                <AutorenewIcon
                  className={`renew-icon rotate`}
                  style={{ fontSize: 16, color: "#19172C" }}
                />
              ) : (
                index + 1
              )}
            </div>
            {/* <div
              className={`label`}
            >
              {step}
            </div> */}
            {index < steps.length - 1 && (
              <div
                className={`line ${index < currentStepIndex ? "active" : ""}`}
              ></div>
            )}
          </div>
        ))}
      </div>
      <div
        className="progress-bar-container"
        style={{ justifyContent: "space-between", padding: 0 }}
      >
        {steps.map((step, index) => (
          <div key={index} className="step-container">
            <div className={`label`}>{step}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
