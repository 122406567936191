import React, { useLayoutEffect, useRef, useState } from "react";
import {
  convertUTCDateToLocalDate,
  getLocalStorageData,
} from "../../../utilities/helper";
import ReactPaginate from "react-paginate";
import { FaBackward, FaForward } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";

import Paper from "@mui/material/Paper";
import CommonTable from "../../../components/organizer/common-table/common-table";
import ErrorHandler from "../../../components/organizer/erro-handler/error-handler";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import useAxiosApiRequest from "../../../hook/useAxiosApiRequest";
import moment from "moment";
import CampaignStatus from "./campaign-status";
function TableList() {
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const tenant = getLocalStorageData("TenantID");
  const responseData = useRef();

  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [totalPage, setTotalPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]); // State variable to hold filtered data
  const [searchTerm, setSearchTerm] = useState("");
  const [tableHeader, setTableHeader] = useState([
    { id: 1, name: "Sl.No" },
    { id: 2, name: "Version" },
    { id: 3, name: "Priority" },
    { id: 4, name: "Description" },
    { id: 5, name: "Campaign Id" },
    // { id: 6, name: "S3 URL" },
    { id: 6, name: "Created At" },
    { id: 7, name: "Updated At" }, 
    { id: 8, name: "Action" }, 
    // { id: 8, name: "Vin" },
    // { id: 9, name: "Thing Name" },
  ]);
  const [showCampaignStatus, setCampaignStatus] = useState(false);
  const [selCampId,setCampId] = useState("")

  useLayoutEffect(() => {
    getCampaign();
  }, []);
  const dummyData = [
    {
      id: 1,
      version: "1.0",
      priority: "High",
      description: "Campaign for new product launch",
      campaign_id: "CAMP001",
      Created_at: moment().subtract(10, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(5, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action"

    },
    {
      id: 2,
      version: "1.1",
      priority: "Medium",
      description: "Quarterly newsletter",
      campaign_id: "CAMP002",
      Created_at: moment().subtract(30, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(15, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action",
    },
    {
      id: 3,
      version: "2.0",
      priority: "Low",
      description: "Customer feedback survey",
      campaign_id: "CAMP003",
      Created_at: moment().subtract(20, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(10, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action",
    },
    {
      id: 4,
      version: "1.2",
      priority: "High",
      description: "Holiday promotion",
      campaign_id: "CAMP004",
      Created_at: moment().subtract(5, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(2, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action"
    },
    {
      id: 5,
      version: "2.1",
      priority: "Low",
      description: "End-of-year clearance sale",
      campaign_id: "CAMP005",
      Created_at: moment().subtract(40, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(30, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action",
    },
    {
      id: 6,
      version: "3.0",
      priority: "Medium",
      description: "Product feedback survey",
      campaign_id: "CAMP006",
      Created_at: moment().subtract(15, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(10, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action",
    },
    {
      id: 7,
      version: "1.3",
      priority: "High",
      description: "Referral program launch",
      campaign_id: "CAMP007",
      Created_at: moment().subtract(25, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(5, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action"
    },
    {
      id: 8,
      version: "1.5",
      priority: "Low",
      description: "Mid-year sale campaign",
      campaign_id: "CAMP008",
      Created_at: moment().subtract(45, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(20, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action",
    },
    {
      id: 9,
      version: "2.2",
      priority: "Medium",
      description: "Loyalty rewards program",
      campaign_id: "CAMP009",
      Created_at: moment().subtract(12, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(8, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action"
    },
    {
      id: 10,
      version: "3.1",
      priority: "High",
      description: "New user onboarding campaign",
      campaign_id: "CAMP010",
      Created_at: moment().subtract(7, "days").format("MMMM Do YYYY, h:mm a"),
      Updated_at: moment().subtract(3, "days").format("MMMM Do YYYY, h:mm a"),
      action:"campaign_action"
    },
  ];


  const getCampaign = async () => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:3000/campaign/submitcampaign",
      method: "GET",
      body: {},
    };

    const response = await apiRequest(data);

    if (response?.campaign_list.length) {
      responseData.current = response?.campaign_list;
      const filteredData = filterFields(response.campaign_list, false);
      setTableData(filteredData);
      setFilteredData(filteredData);
      const dataCount = filteredData.length;
      const itemsPerPage = 10;
      const totalPages = Math.ceil(dataCount / itemsPerPage);
      setTotalPage(totalPages);
    }
    
  };

  const filterFields = (data, hide) => {
    return data.map((item, index) => {
      const {
        version,
        priority,
        description,
        campaign_id,
        created_at,
        updated_at,
      } = item; // Extract necessary properties

      if (hide) {
        return {
          id: index + 1,
          version: version,
          priority: priority,
          description: description,
          campaign_id: campaign_id,
          Created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          Updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
          action:"campaign_action"
        };
      } else {
        return {
          id: index + 1,
          version: version,
          priority: priority,
          description: description,
          campaign_id: campaign_id,
          Created_at: moment(created_at).format("MMMM Do YYYY, h:mm a"),
          Updated_at: moment(updated_at).format("MMMM Do YYYY, h:mm a"),
          action:"campaign_action"
        };
      }
    });
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected); // Update the current page
  };

  const closeCampaignStatus = () => {
    setCampaignStatus(false);
  };

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage; // Calculate offset for pagination
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);

  const showStatus =(key,data)=> {
    if(key == "status"){
      setCampId({campaignId:data?.campaign_id,date:data?.Created_at})
      setTimeout(() => {
        setCampaignStatus(true)
      }, 50);
    }
  }
  return (
    <ErrorHandler statusCode={200} loading={false}>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "1%" }}>
        <CommonTable
          tableHeader={tableHeader}
          data={currentPageData}
          onClick={showStatus}
          showMoreIcon={false}
        />

        <br></br>

        <div
          style={{
            border: "1px solid #d3d3d3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "5px",
            paddingTop: "15px",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: "gray",
              fontSize: 13,
              fontFamily: "Rubik",
            }}
          >
            Showing {offset + 1} to {offset + currentPageData.length} of{" "}
            {filteredData.length} entries
          </p>
          <div style={{ marginTop: 2 }}>
            <ReactPaginate
              previousLabel={
                <ArrowBackIosNewOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              nextLabel={
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>

        {showCampaignStatus && (
          <CampaignStatus
            openRecordList={showCampaignStatus}
            onClose={closeCampaignStatus}
            campaignId={selCampId}
          />
        )}
      </Paper>
    </ErrorHandler>
  );
}

export default TableList;
